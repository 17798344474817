import React from "react";
import { Link } from "react-router-dom";

const SiteLinks = ({ handleLogo }) => {
  return (
    <ul className="relative w-full flex h-full flex-col items-start mt-0 md:mt-4 lg:mt-4 xl:mt-10 2xl:mt-10">
      <li className="ml-8 font-roboto font-medium text-2xl">
        <Link onClick={() => handleLogo()} to="/">
          Bio
        </Link>
      </li>
      {/*
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/portfolio">
          Portfolio
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/articles">
          Articles
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/books">
          Books
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/classes">
          Classes
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/consulting">
          Consulting
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/talks">
          Talks
        </Link>
      </li>
      <li className="ml-8 font-roboto font-medium text-2xl mt-4">
        <Link onClick={() => handleLogo()} to="/press">
          Press
        </Link>
      </li>
      */}
    </ul>
  );
};

export default SiteLinks;
