import React from "react";

const Footer = () => {
  return (
    <footer className='bottom-0 z-10 flex flex-row justify-center p-2 border border-gray-200'>
      <h1 className='font-poppins font-light text-sm'>
        Copyright Ali Tufan Koç {new Date().getFullYear()},
      </h1>
      <h2 className='font font-poppins font-light text-sm ml-2'>
        Made With ❤️ by
      </h2>
      <a
        href='https://github.com/kaanmertkoc'
        target='_blank'
        className='font font-poppins font-normal text-sm ml-2 hover:underline'
        rel='noreferrer'
      >
        Kaan Mert Koç
      </a>
    </footer>
  );
};

export default Footer;
