import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./screens/LandingPage";
import Header from "./components/Header";
import BooksPage from "./screens/BooksPage";
import PortfolioPage from "./screens/PortfolioPage";
import ClassesPage from "./screens/ClassesPage";
import TalksPage from "./screens/TalksPage";
import PressPage from "./screens/PressPage";
import ConsultingPage from "./screens/ConsultingPage";
import ArticlePage from "./screens/ArticlePage";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="mb-24" />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" />} />
        {/*
        <Route path="/articles" element={<ArticlePage />} />
        <Route path="/books" element={<BooksPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/classes" element={<ClassesPage />} />
        <Route path="/talks" element={<TalksPage />} />
        <Route path="/press" element={<PressPage />} />
        <Route path="/consulting" element={<ConsultingPage />} />
        */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
