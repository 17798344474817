import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

const PortfolioCard = ({
  title,
  image,
  date,
  description,
  company,
  position,
  kolaj,
}) => {
  return (
    <div className="pt-6">
      <h2 className="flex flex-row justify-center items-center font-poppins font-normal xl:text-lg 2xl:text-lg lg:text-lg md:text-lg sm:text-lg mr-2 text-center lg:text-center xl:text-center 2xl:text-center md:text-center sm:text-center">
        {title}
        <p className="font-poppins font-ligt text-center ml-2 text-xs italic">
          {date}
        </p>
      </h2>
      <h2 className="font-poppins font-light xl:text-sm 2xl:text-sm lg:text-sm md:text-sm sm:text-sm mr-2 text-center lg:text-center xl:text-center 2xl:text-center md:text-center sm:text-center">
        {company}
      </h2>
      <h2 className="font-poppins font-light xl:text-sm 2xl:text-sm lg:text-sm md:text-sm sm:text-sm mr-2 text-center lg:text-center xl:text-center 2xl:text-center md:text-center sm:text-center">
        Role: {position}
      </h2>
      <div className="flex content-center items-center xl:flex-row 2xl:flex-row lg:flex-row lg:items-center xl:items-center 2xl:items-center md:flex-col sm:flex-col flex-col  mb-10 ">
        {image.length > 1 ? (
          <Swiper
            navigation={true}
            spaceBetween={10}
            className=" w-full h-full 2xl:w-full 2xl:h-full xl:w-full xl:h-full lg:w-full lg:h-full md:w-1/4 md:h-1/4 sm:w-1/4 sm:h-1/4"
            centeredSlides={true}
          >
            <div>
              {image.map((i, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={i} className="" alt="carousel" />
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper>
        ) : image.length === 1 ? (
          <img src={image[0]} className="w-1/6 h-1/6 mt-4" alt="carousel" />
        ) : null}

        <div className="ml-8 mr-6">
          <p className="font-poppins font-extralight xl:text-lg 2xl:text-lg lg:text-lg md:text-lg sm:text-lg mt-10 mr-4">
            {description}
          </p>
        </div>
      </div>
      {kolaj && (
        <>
          <div className="flex flex-row items-center justify-center mb-10">
            <img src={kolaj} className="w-1/2 h-1/4" alt="kolaj" />
          </div>
        </>
      )}
      <div className="flex flex-col items-center justify-center place-items-center">
        <div className="w-3/4 h-0.5 bg-gray-200  m-12" />
      </div>
    </div>
  );
};

export default PortfolioCard;
