import React from "react";
import { GrClose } from "react-icons/gr";
import SiteLinks from "./SiteLinks";
import SocialLinks from "./SocailLinks";

const HamburgerMenu = ({ handleLogo }) => {
  return (
    <div className="absolute z-100 right-0 top-0 w-64 h-screen bg-gray-50 flex flex-col items-end">
      <GrClose
        className="h-8 w-8 cursor-pointer mt-6 mr-10 sm:h-4 sm:w-4 sm:mt-6 md:mt-8 lg:mt-6 xl:mt-6 2xl:mt-6 md:h-8 md:w-8 lg:h-8 lg:w-8 xl:h-8 xl:w-8 2xl:w-8 2xl:h-8"
        onClick={() => handleLogo()}
      />
      <SiteLinks handleLogo={handleLogo} />
      {/*
      <div className="relative w-full flex flex-col justify-between items-start ">
        <a
          href="/mail"
          className="font-poppins font-thin text-lg hover:underline underline-offset-1 ml-8 mt-10"
        >
          Contact: mail
        </a>
      </div>
      */}
      <SocialLinks />
    </div>
  );
};

export default HamburgerMenu;
