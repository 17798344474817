import React, { useEffect } from "react";
import BioText from "../components/Bio/BioText";

const LandingPage = () => {
  useEffect(() => {
    document.title = "Ali Tufan Koç | Bio";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative flex flex-col w-screen justify-items-center items-center'>
      <div className='relative lg:top-40 xl:top-40 2xl:top-40 md:top-40  md:left-5 2xl:left-5 xl:left-10 lg:left-5 md:fixed lg:fixed xl:fixed 2xl:fixed'>
        <img
          className='p-4 xl:w-80 xl:h-80 lg:h-auto lg:w-auto md:w-80 md:h-80 '
          src={require('../images/bio.jpeg')}
          alt='bio'
        />
        <h2 className='font-poppins text-left font-light text-sm mt-1 mr-5'>
          (Image: Muhsin Akgün)
        </h2>
        <h2 className='font-poppins text-left font-light text-sm mt-2'>
          Ali can be reached directly at
          <a
            className='font-poppins font-medium text-sm underline ml-1 mb-1'
            href='mailto:atk@alitufankoc.com'
          >
            atk@alitufankoc.com
          </a>
        </h2>
      </div>

      <BioText />
    </div>
  );
};

export default LandingPage;
