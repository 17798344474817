import React from "react";

const BioText = () => {
  return (
    <div className="flex flex-col justify-items-center items-end mt-10 mr-10">
      <p className="font-poppins font-light 2xl:w-3/4 xl:w-3/5 lg:w-2/4 md:w-2/4 ml-10">
        New York-based Ali Tufan Koç is an editorial director, global creative
        strategist, writer, and speaker/lecturer.
        <br />
        <br />
        Koç began his professional career as a journalist and a reporter at
        <a
          href="https://en.wikipedia.org/wiki/Tempo_(Turkish_magazine)"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          TEMPO
        </a>
        in Turkey, a weekly news magazine, at the age of 19. This experience
        sparked his passion for media. He graduated from Business School of SUNY
        New Paltz and Istanbul Technical University in 2004 and later went on to
        complete “Print & Digital Media” program at New York University in 2016
        and followed by a master’s degree with a focus on digital innovation in
        journalism at New York University Arthur L. Carter Journalism Institute
        in 2019.
        <br />
        <br />
        In 2010, at the age of 24, Koç became one of the founding editors of GQ
        Turkey. His cover stories with international talents appeared and
        mentioned in international media,{" "}
        <a
          href="https://www.youtube.com/watch?v=k155FCLUCNc"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          including CONAN on TBS.
        </a>
        He then went on to spend the next six years as a contributing editor for
        GQ as well as a frequent contributor to both Vogue and Condé Nast
        Traveler Turkey. He returned to Condé Nast Publications in Turkey as
        Editor in Chief at GQ in 2020, and Publishing Director at Vogue in 2021,
        respectively.
        <br />
        <br />
        He repositioned GQ by transforming into a multi-platform brand excelling
        in cultural diversity, modern masculinity, mental health, and climate
        change, where it received the first ever Magazine of the Year Award for
        excellence in digital platforms. Under his tenure, GQ Turkey has
        launched three new titles,(
        <a
          href="https://www.magzter.com/TR/Turkuvaz-Dergi/GQ-Denge/Mens-Magazines/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline mr-1"
        >
          GQ BALANCE,
        </a>
        <a
          href="https://www.magzter.com/TR/Turkuvaz-Dergi/GQ-Denge/Mens-Magazines/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          GQ BALANCE,
        </a>
        <a
          href="https://gq.com.tr/hype"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          GQ HYPE,
        </a>
        <a
          href="https://models.com/work/gq-turkey-gq-turkey-july-2021-cover"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1"
        >
          Travel by GQ
        </a>
        ) built a new digital division and assets and created several impactful
        projects including
        <a
          href="https://www.youtube.com/playlist?list=PLsrnQTmeTTwgbVnXmTrst7JAXH6565cPl"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          “GQ Masterclass”,
        </a>
        <a
          href="https://gq.com.tr/gq-sports/gq-young-athlete-awards-genc-sporcularimizi-onurlandirma-hareketi"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          “Young Athlete Awards”,
        </a>
        <a
          href="https://www.heforshe.org/en/heforshe-turkey-and-gq-magazine-turkey-launch-new-podcast-series"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          “HeforShe x GQ”,
        </a>
        <a
          href="https://www.condenast.com/news/gq-bar-bodrum-opens"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          “GQ Bar in Bodrum”,
        </a>
        as well as GQ’s Climate Project, a series of exhibitions, panels, and
        editorials on earth positivity in fashion.
        <br />
        <br />
        In 2011, Koç also joined
        <a
          href="https://en.wikipedia.org/wiki/H%C3%BCrriyet"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          Hurriyet
        </a>
        (one Turkey’s major newspapers) as a feature editor, becoming one of the
        youngest editors in the publication’s 50-year history. While at
        Hurriyet, he founded Fingertips Digital, where, to this day, he works
        with brands and professionals in the categories of brand development,
        content strategy, and creative consultant. He has partnered with many
        international brands including Pernod Ricard, Soho House, Red Bull,
        <a
          href="https://sanayi313.com/en/paper/editors/alitufankoc/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          Sanayi 313,
        </a>
        and
        <a
          href="https://sanayi313.com/en/paper/editors/alitufankoc/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          Alfardan Group in Qatar.
        </a>
        <br />
        <br />
        In 2018, Koç, who had long had the vision to create new ways of
        storytelling, launched the alternative media outlet,
        <a
          href="https://www.amazon.com/Sumac/dp/B07XR5J2SS"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          Sumac,
        </a>
        a community-based print publication about food & memories and was
        selected as one of the
        <a
          href="https://monocle.com/magazine/the-drinking-and-dining-directory/2019/taste-of-success/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          “fresh thinkers who are changing and challenging the food industry
          today” by Monocle Magazine.
        </a>
        <br />
        <br />
        Having studied Business, Culture, and Media, Koç is inspired by the
        wisdom in culture and the power of meaningful experiences that help us
        live wisely as well as the potential of human design to challenge how we
        live and offer ways in which we might live better. He has delivered more
        than 50 talks and
        <a
          href="https://www.timeout.com/newyork/things-to-do/how-to-live-in-the-city-new-york"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          workshops in New York
        </a>
        and Istanbul. He regularly facilitates
        <a
          href="https://www.timeout.com/newyork/things-to-do/how-to-live-in-the-city-new-york"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          "How to live in The City",
        </a>
        "How to Enjoy Your Life", and
        <a
          href="https://www.timeout.com/newyork/things-to-do/how-to-live-in-the-city-new-york"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          "Storytelling in the Digital Era",
        </a>
        classes at{" "}
        <a
          href="https://www.theschooloflife.com/istanbul/program/herey/how-to-live-in-the-city/"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          The School of Life.
        </a>
        <br />
        <br />
        His first book, a novella called{" "}
        <a
          href="https://www.amazon.com/Kimse-Sehirde-Zihnin-Kalbin-%C3%96yk%C3%BCs%C3%BC/dp/6052182423"
          target="_blank"
          rel="noreferrer"
          className="font-poppins font-medium underline ml-1 mr-1"
        >
          "No One Dies in This City: A 24-hour Journey in New York Subways"
        </a>{" "}
        was published in 2019 in Turkey. The book has appeared in many national
        media outlets in Turkey Including Vogue, GQ, and ELLE. His second book,
        “Life Lessons from Legends”, a collection of long-form interviews with
        cultural figures including David Lynch, Marina Abramovic, and Paul
        Auster, will be published in Spring 2022, also in Turkey.
        <br />
        <br />
        Born and raised in Istanbul, Koç currently resides in Brooklyn, New
        York.
        <br />
        <br />
      </p>
    </div>
  );
};

export default BioText;
