import React from "react";
import { BiLink } from "react-icons/bi";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

const PressTitle = ({ title, arr }) => {
  return (
    <div className="pt-6">
      <h2 className="font-poppins font-semibold italic">{title}</h2>
      {arr.map((press, index) => {
        return (
          <div key={index} className="mt-4">
            <PressTitleCards press={press} />
          </div>
        );
      })}
    </div>
  );
};

const PressTitleCards = ({ press }) => {
  return (
    <div className="">
      {press?.url ? (
        <a
          className="hover:underline"
          href={press.url}
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex flex-row items-center">
            <h3 className="font-poppins font-medium 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm text-sm mr-2">
              {press?.title}
            </h3>
            <p className="font-poppins font-light text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm mr-2">
              {press?.medium}
            </p>
            <p className="font-poppins font-light text-lg">{press?.date}</p>
            <BiLink className="ml-2 w-4 h-4 2xl:w-6 2xl:h-6 xl:w-6 xl:h-6 lg:w-6 lg:h-6 md:w-6 md:h-6 sm:w-4 sm:h-4" />
          </div>
        </a>
      ) : (
        <div className="flex flex-row">
          <h3 className="font-poppins font-medium 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm text-sm mr-2">
            {press?.title}
          </h3>
          <p className="font-poppins font-light text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm text-left mr-2">
            {press?.medium}
          </p>
          <p className="font-poppins font-light text-sm 2xl:text-lg xl:text-lg lg:text-lg md:text-sm sm:text-sm text-left">
            {press?.date}
          </p>
        </div>
      )}
      {press?.images && press?.images?.length > 0 && (
        <div className="mt-10 flex flex-row items-center content-center justify-center">
          <Swiper className="w-1/2" navigation={true}>
            {press?.images.map((image, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="flex flex-row items-center justify-center"
                >
                  <img className="w-1/4 h-1/4" src={image} alt="carousel" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default PressTitle;
