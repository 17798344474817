export const talks = [
  {
    title: "CAREER DAY",
    place: "(Online)",
    company: "at Yeditepe University",
    date: ["November 2021"],
  },
  {
    title: "GQ's EARTH POSITIVITY IN FASHION",
    place: "(Istanbul, Turkey)",
    company: "at Fashion Week Istanbul",
    date: ["October 15, 2021"],
    url: "https://gq.com.tr/guncel/gq-turkiye-earth-positivity-in-fashion-fotograf-ve-moda-tasarim-sergisi-paneli#p=23",
  },
  {
    title: "GQ's CLIMATE, ART AND FASHION",
    place: "(Istanbul, Turkey)",
    company: "at Contemporary Istanbul",
    date: ["October 10, 2021"],
  },
  {
    title: "PANEL: MEDIUM IS THE MESSAGE",
    place: "(Istanbul, Turkey)",
    company: "at Sonar Istanbul",
    date: ["October 3, 2021"],
    url: "https://sonaristanbul.com/en/2021/artists/panel-medium-is-the-massage-by-aposto-with-ali-tufan-koc-orhun-canca",
  },
  {
    title: "CAREER DAY",
    place: "(Online)",
    company: "at Anadolu University",
    date: [""],
    url: "https://www.youtube.com/watch?v=ZniOJE3MtMA&t=5695s",
  },
  {
    title: "GQ WEBINAR: HOW TO MOVE FORWARD",
    place: "(Onilne)",
    company: "",
    date: ["November 18, 2020"],
    url: "https://www.youtube.com/watch?v=EN46CkZfX5Y&list=PLsrnQTmeTTwh2GrAuzZ0njHQDl-xbsbOZ",
  },
  {
    title: "CREATIVE STORYTELLING THROUGH FOOD",
    place: "(Istanbul, Turkey)",
    company: "at Atolye Istanbul",
    date: ["Jan 18, 2020"],
    url: "https://atolye.io/en/event/creative-storytelling-through-food/",
  },
  {
    title: "FARM TO BACKYARD",
    place: "(New York, USA)",
    company: "at Edy's Grocer",
    date: ["Sebtember 29, 2019", "October 20, 2019"],
    url: "http://www.edouardmassih.com/popup/october-brunch",
  },
  {
    title: "A SUMAC SOIREE & CONVERSATIONS",
    place: "(New York, Usa)",
    company: "at Archestratus Books & Foods",
    date: ["August 16, 20"],
    url: "https://www.facebook.com/ArchestratusBooksandFoods/posts/2318728695005588/",
  },
  {
    title: "IST.ROME.FEST",
    place: "(Rome, Italy)",
    company: "",
    date: ["June 1, 2019"],
    url: "https://www.maxxi.art/en/events/ist-fest-rome/",
  },
  {
    title: "CREATIVE MORNINGS: PRESERVE",
    place: "(Istanbul, Turkey)",
    company: "",
    date: ["May 31, 2019"],
    url: "https://creativemornings.com/talks/ali-tufan-koc",
  },
];
export const bookSessions = [
  {
    theme: `"The Honesty Manifesto"`,
    with: "w / Gaye Su Akyol",
    date: "January 27, 2019",
    url: "https://bantmag.com/ali-tufan-koc-kimse-olmez-bu-sehirde-kitabini-dost-sohbetleriyle-tanitiyor/",
  },
  {
    theme: `"The Courage to Create"`,
    with: "w / Taner Ceylan",
    date: "January 28, 2019",
  },
  {
    theme: `"The Voice Within Ourselves"`,
    with: "w / Tuba Unsal",
    date: "January 29, 2019",
    url: "https://assemblybuildings.com/events/the-voices-within-us-114",
  },
  {
    theme: `"The Wisdom of Hope"`,
    with: "w / Gaye Su Akyol",
    date: "January 27, 2019",
    url: "https://mobile.twitter.com/feriyeistanbul/status/1129427570859094016/photo/1",
  },
  {
    theme: `"The Nature of Rebirth"`,
    with: "w / Berrak Tuzunatac",
    date: "May 27, 2019",
    url: "https://mobile.twitter.com/feriyeistanbul/status/1130754218246254592/photo/1",
  },
];
export const moreTalks = [
  {
    title: `PANEL: "WHERE IS HOME"`,
    place: "(Istanbul, Turkey)",
    company: "The School of Life",
    date: ["January 16, 2019"],
    url: "https://www.theschooloflife.com/istanbul/program/herey/ev-gitmek-kalmak/",
  },
  {
    title: `HOW TO LIVE IN THE CITY`,
    place: "(New York, USA)",
    company: "The Assemblage Nomad",
    date: ["December 18, 2018"],
    url: "https://www.timeout.com/newyork/things-to-do/how-to-live-in-the-city-new-york",
  },
  {
    title: `CONVERSATION NIGHTS, THEME: HOPE`,
    place: "(Istanbul, Turkey)",
    company: "Sanayi 313",
    date: ["October 13, 2018"],
    url: "https://www.facebook.com/watch/?v=1517086418387179",
  },
  {
    title: `THE ART OF HAPPINESS`,
    place: "(Izmir, Turkey)",
    company: "Club Marvy",
    date: ["May 25-27, 2018"],
    url: "https://www.clubmarvy.com/en/beach-club-resort-turkey/art-of-happiness-en-1",
  },
  {
    title: `CONVERSATION NIGHTS, THEME: BODY`,
    place: "(Istanbul, Turkey)",
    company: "co-hosted with Vogue Turkey",
    date: ["May 14, 2018"],
    url: "https://www.facebook.com/watch/?v=1517086418387179",
  },
];

export const kolektifTalks = [
  {
    with: "w / Mehmet Gurs & Ertugrul Ozkok,",
    date: "Jan'17",
    url: "https://www.youtube.com/watch?v=OqpoRpSYT2s&list=PLffpuPkY9qBYeVOrgE4kbHymhXDfgnc0-&index=6",
  },
  {
    with: "w / Sahika Ercumen  Gurs & Ertugrul Ozkok,",
    date: "Nov'16",
    url: "https://www.youtube.com/watch?v=dsdPO0QKh5k&list=PLffpuPkY9qBYTK6PaRXqPZOQbnY7f9GkX",
  },
  {
    with: "w / Erdil Yasaroglu,",
    date: "Sep'16",
    url: "https://www.youtube.com/watch?v=7cBYoGW07wg&list=PLffpuPkY9qBYyicp21SEMRo3pJh4wzQz8&index=4",
  },
  {
    with: "w / Cem Yılmaz,",
    date: "June'16",
    url: "https://www.youtube.com/watch?v=U-paOsyaa_c&t=74s",
  },
  {
    with: "w / Gonca Vuslateri & Emre Karayel,",
    date: "June'16",
    url: "https://www.youtube.com/watch?v=9cFAUCFu0eY&list=PLffpuPkY9qBbU7xwPnn_UzEKKcC2z4Gmb",
  },
  {
    with: "w / Berrak Tuzunatac & Muhsin Akgun,",
    date: "Feb'16",
    url: "https://www.youtube.com/watch?v=csTHEnHpiOE&list=PLffpuPkY9qBYc1OOOA5ezxDmHNnjUMGy7",
  },
  {
    with: "w / Can Bonomo & Sarp Apak,",
    date: "Feb'16",
    url: "https://www.youtube.com/watch?v=pYzmz-TZrww&list=PLffpuPkY9qBbm8Xw2sm_hoEmN7fvCVmsk&index=2",
  },
  {
    with: "w / Esra Dermancioglu & Aykut Comert,",
    date: "Dec'15",
  },
  {
    with: "w / Nil Karaibrahimgil & Nihat Odabasi,",
    date: "Sep'15",
    url: "https://www.youtube.com/watch?v=nxuoFkOZShw&list=PLffpuPkY9qBYA8ERL_Z33FsGd32Ie4VXz&index=4",
  },
  {
    with: "w / Mirgun Cabas & Fem Gucluturk,",
    date: "June'15",
  },
  {
    with: "w / Tuba Unsal & Hasan Bas,",
    date: "May'15",
  },
];

export const istanbulArts = [
  {
    with: "w / Jean-Charles de Castelbajac & Robert Montogomey,",
    date: "June'16",
    url: "https://www.youtube.com/watch?v=O841Gg_sTno",
  },
  {
    with: "w / Jean-Charles de Castelbajac & Robert Montogomey,",
    date: "June'16",
    url: "https://www.youtube.com/watch?v=O841Gg_sTno",
  },
  {
    with: "w / Aimee Mullins,",
    date: "June'16",
    url: "https://www.youtube.com/watch?v=W_4irINbkIs&t=186s",
  },
  {
    with: "w / Alex Prager & Arslan Sukan,",
    date: "May'15",
    url: "https://www.youtube.com/watch?v=4-Wfl6MYv1s",
  },
];

export const mediaPress = [
  {
    title:
      "Ali Tufan Koc & “Musician of the Year” Edis talks about GQ MOTY Awards,",
    date: "January 2021",
    medium: "NTV",
    url: "https://www.youtube.com/watch?v=LJHKvRzD3ps",
  },
  {
    title:
      "Ali Tufan Koc & “Woman of the Year” Kalben talks about GQ MOTY Awards,",
    date: "December 2020",
    url: "https://www.youtube.com/watch?v=7p1EpX29XyM",
  },
  {
    title: "GQ Bar Bodrum Opens",
    date: "August 2021",
    url: "https://www.condenast.com/news/gq-bar-bodrum-opens",
  },
  {
    title: `“How GQ Reinvent Itself Under a New Leadership in Turkey”,`,
    date: "August 2020",
    medium: "The Brand Age",
  },
];

export const magazineLaunch = [
  {
    title: `“Taste of Success: Entrepreneurs turning a plucky vision and top-notch ingredients into fulfilling business”,`,
    date: "Summer 2019,",
    medium: "Monocle Magazine",
    images: [
      require("../images/monocle1.jpg"),
      require("../images/monocle2.jpg"),
    ],
  },
  {
    title: `Sumac Magazine Debuts,`,
    date: "June 2019,",
    medium: "Bone Magazine",
    images: [
      require("../images/bone1.jpg"),
      require("../images/bone2.jpg"),
      require("../images/bone3.jpg"),
    ],
  },
  {
    title: `Review: "On Sumac's Insipiring Launch Dinner,"`,
    date: "June 2019,",
    medium: "Onur Basturk",
    images: [require("../images/onur1.jpg")],
  },
  {
    title: `Review: "A Very Exciting Magazine: Sumac,"`,
    date: "June 2019,",
    medium: "Ertugrul Ozkok",
    images: [require("../images/ertugrul1.jpg")],
  },
  {
    title: `"The Conversation Gets Eaiser When There's Food Around"`,
    date: "May 2019,",
    medium: "Hurriyet Newspaper",
    images: [
      require("../images/hurriyet1.PNG"),
      require("../images/hurriyet2.jpg"),
    ],
  },
];

export const bookLaunch = [
  {
    title: `"Human Beings Had Always Problem with Hope,"`,
    date: "October 2019,",
    medium: "Book Review Section",
    images: [require("../images/review1.jpg")],
  },
  {
    title: `"The Answer of Big Questions is Often Hidden in Small Moments"`,
    date: "June 2019,",
    medium: "Book Review Section",
    images: [require("../images/review2.jpg")],
  },
  {
    title: `“Adapting to Change is Our Only Chance of Survival”`,
    date: "April 2019,",
    medium: "Based Istanbul",
    images: [require("../images/based1.jpg")],
  },
  {
    title: `"A Story of Two Cities"`,
    date: "April 2019,",
    medium: "GQ",
    images: [],
  },
  {
    title: `"From the Underground,"`,
    date: "March 2019",
    medium: "Vogue",
    images: [],
  },
  {
    title: `"24 Hour in Subway",`,
    date: "March 2019,",
    medium: "ELLE",
    images: [],
  },
  {
    title: `"Going Through Major Life Change",`,
    date: "March 2019,",
    medium: "ALL Magazine",
    images: [],
  },
  {
    title: `"City of People",`,
    date: "February 2019,",
    medium: "Time Out Istanbul",
    images: [],
  },
  {
    title: `Feature: "From Ali Tufan Koc's book launch party"`,
    date: "January 2019,",
    medium: "Vogue",
    images: [
      require("../images/booklaunch1.png"),
      require("../images/booklaunch2.png"),
      require("../images/booklaunch3.png"),
      require("../images/booklaunch4.png"),
      require("../images/booklaunch5.png"),
      require("../images/booklaunch6.png"),
      require("../images/booklaunch7.png"),
      require("../images/booklaunch8.png"),
      require("../images/booklaunch9.png"),
      require("../images/booklaunch10.png"),
      require("../images/booklaunch11.png"),
      require("../images/booklaunch12.png"),
      require("../images/booklaunch13.png"),
      require("../images/booklaunch14.png"),
      require("../images/booklaunch15.png"),
      require("../images/booklaunch16.png"),
      require("../images/booklaunch17.png"),
      require("../images/booklaunch18.png"),
      require("../images/booklaunch19.png"),
      require("../images/booklaunch20.png"),
    ],
  },
  {
    title: `Review: "His launch event is creating hype as well as the book"`,
    date: "January 2019,",
    medium: "Cagdas Ertuna",
    images: [],
  },
  {
    title: `"We Should Give Ourselves Permission to Make Mistakes, Create Conflictions and Do the Wrong Thing"`,
    date: "January 2019,",
    medium: "Hurriyet Newspaper",
    images: [],
  },
];

export const classesTalks = [
  {
    title: `"How to Live in the City",`,
    date: "December 2018,",
    medium: "Time Out New York",
    images: [require("../images/timeout.png")],
  },
  {
    title: "Mission: Happiness,",
    date: "January 2018,",
    medium: "Hurriyet",
    images: [require("../images/mission.jpg")],
  },
  {
    title: "Live Like a True City Person,",
    date: "April 2017,",
    medium: "Hurriyet",
    images: [require("../images/live.png")],
  },
  {
    title: `"Istanbul Arts & Culture Festival",`,
    date: "May 2015",
    medium: "Vogue Italia",
    images: [require("../images/arts.png")],
  },
];

export const portfolioImages = [
  require("../images/city1.JPG"),
  require("../images/city2.jpg"),
  require("../images/city3.jpg"),
  require("../images/city4.jpg"),
  require("../images/city5.jpg"),
  require("../images/city6.jpg"),
  require("../images/howtocity4.JPG"),
  require("../images/howtocity5.JPG"),
  require("../images/howtocity6.jpg"),
];

export const portfolios = [
  {
    company: "Quarterly Print Magazine",
    title: "GQ Turkey  ",
    date: "(2020 - 2021)",
    image: [require("../images/gq2.jpg")],
    kolaj: require("../images/gq1.jpg"),
    description:
      "Under Ali Tufan Koc’s leadership, GQ was relaunched in Turkey by redefining its business planning, editorial and creative direction, growth & audience development by leading audience-centric, data-driven, and digital-first strategy. He repositioned GQ by transforming into a multi-platform brand excelling in cultural diversity, modern masculinity, mental health, and climate change, resulting in receiving the first ever Magazine of the Year Award for excellence in digital platforms.",
    position: "Editor-in Chief & Publishing Director",
  },
  {
    company: "Quarterly Print Magazine",
    title: "GQ Turkey  ",
    date: "(2020 - 2021)",
    image: [require("../images/hype1.jpg")],
    kolaj: require("../images/hype2.jpg"),
    description:
      "Under Ali Tufan Koc’s leadership, GQ was relaunched in Turkey by redefining its business planning, editorial and creative direction, growth & audience development by leading audience-centric, data-driven, and digital-first strategy. He repositioned GQ by transforming into a multi-platform brand excelling in cultural diversity, modern masculinity, mental health, and climate change, resulting in receiving the first ever Magazine of the Year Award for excellence in digital platforms.",
    position: "Editor-in Chief & Publishing Director",
  },
  {
    company: "Digital Brand Expansion and Audience Development Project",
    title: "GQ PODCASTS & NEWSLETTERS ",
    date: "(2020 – 2021)",
    image: [require("../images/podcast1.png")],
    kolaj: require("../images/podcast2.jpg"),
    description:
      "Launched in May 2020, “GQ Podcasts” is a digital division of GQ Turkey as well as a major part of GQ’s audience- centric, digital-first strategy. The topics covered are mainly personal growth, mental health, gender equality, creativity, and pop culture. The series are known as community-oriented, interactive, short, informative, and entertaining. With special guest celebrities, experts, and community members, “GQ Podcasts” brings a fresh approach to audio content platforms.",
    position: "Founding Executive Director, Business & Creative Strategy",
  },
  {
    company: "360 Campaign (Panels, Exhibitions, Print and Digital Editorials)",
    title: "GQ CLIMATE PROJECT ",
    date: "(2021)",
    image: [
      require("../images/iklim2.JPG"),
      require("../images/iklim3.JPG"),
      require("../images/iklim4.JPG"),
      require("../images/iklim5.JPG"),
      require("../images/iklim6.JPG"),
      require("../images/iklim7.jpeg"),
      require("../images/iklim8.png"),
      require("../images/iklim9.png"),
      require("../images/iklim10.png"),
      require("../images/iklim11.JPG"),
    ],
    kolaj: require("../images/iklim1.png"),
    description:
      "GQ’s Climate Project, “Earth Positivity in Fashion”, creates a diverse community of designers, artists, and activists through a series of rewarding experiences, empowering panels, exhibitions, discussions, hands-on activations, and keynotes, in addition to a partnership with United Nation’s “Act Now” campaign, Contemporary Istanbul, and Fashion Week Istanbul.",
    position: "Creator/Executive Director",
  },
  {
    company: "360 Social Project",
    title: "GQ TURKEY X UNITED NATIONS HE FOR SHE ",
    date: "(2020 – 2021)",
    image: [],
    description:
      "Launched first-of-its-kind partnership between GQ and UN Women and made GQ Turkey the first men magazine as a global media compact for HeforShe Campaign, led to producing a digital magazine, original podcast series, GQ Masterclass episodes, and “Equality Awards” at MOTY to shining light on gender equality, modern masculinity, and social diversity.",
    position: "Executive Director ",
  },
  {
    company: "Brand Expansion & Business Development Projects",
    title: "GQ BALANCE, TRAVEL, STYLE ",
    date: "(2020 – 2021)",
    image: [],
    description:
      "Aimed to be a curation of “self-balance” focused content, GQ Balance acts as a platform that offers personal guidance to the modern man. In these times where balance is lost and staying fit and healthy is getting harder by the day, GQ Balance helps to guide the modern man on this journey on both physical and mental wellness.",
    position: "Founding Executive Director",
  },
  {
    company: "Multimedia Project (Text, Video, and Audio Editorial Series)",
    title: "YOUNG ATHLETE AWARDS ",
    date: "(2021)",
    image: [require("../images/ya3.gif")],
    kolaj: require("../images/ya1.jpeg"),
    description:
      "Launched GQ Sports division as well as the “Young Athlete Awards” project with an extension of podcast and short-form video series aiming to support non mainstream branches and U21 athletes. A movement to support, honor and help those who proved themselves to be the future of sport in Turkey, YAA is the one and only award project in Turkey with a focus on young athletes’ stories and achievements exclusively. Its mission is to introduce more young people into sport, and to remind the connecting and healing power of it.",
    position: "Executive Director",
  },
  {
    company: "Restaurant/Bar",
    title: "GQ BAR IN BODRUM ",
    date: "(2021)",
    image: [require("../images/gqbodrum.jpeg")],
    description:
      "GQ Bar Bodrum is one slice of the GQ world, offering visitors an exquisite gastronomical and social experience, in a beautiful setting with a creatively stylish menu.",
    position: "Creative Strategist",
  },
  {
    company: "Monthly Magazine",
    title: "VOGUE TURKEY “OPTIMISM” CAMPAIGN ",
    date: "(2021)",
    image: [
      require("../images/vogue1.jpeg"),
      require("../images/vogue2.png"),
      require("../images/vogue3.png"),
      require("../images/vogue4.png"),
    ],
    description:
      "During his tenure at Vogue, Koc, ideated and executed the relaunch edition working with Editor-in Chief, Deborah Zakuto. He co-developed and executed the “optimism” campaign aligning with the new brand positioning, while the title was repositioned to better reflect the interests and views of the modern audience. The campaign features collaboration with local illustrators, influencer communication, and “optimism kit” launch. ",
    position: "Publishing Director",
  },
  {
    company:
      "A Solution Set for Trust, Censorship, and Revenue Problem in Media in Turkey ",
    title: "THE MESSAGING APPS NEWSROOM PROJECT ",
    date: "(2020)",
    image: [],
    description:
      "Trust, revenue problems and censorship are the biggest pain points in media in countries like Turkey where political control over media outlets and news distribution is high. Could news outlets that only exist in messaging apps like Telegram be the answer to save independent journalism in these countries? Using a human centered design model, this project introduces us to an alternative way of news platform and a new revenue form and a toolbox for media outlets about how to use messaging apps more audience centered.",
    position: "Master’s Thesis ",
  },
  {
    company:
      "Engagement, Revenue and Growth Strategies/Solutions for Audience-Oriented Newsletter Products",
    title: "CONDE NAST INTERNATIONAL x NYU ",
    date: "(2020)",
    image: [],
    description:
      "‘Community building’ has become a key factor in the publishing world in the era of Covid-19. The audience wants to see themselves and feel to be a part of the publication they read. Considering the accessibility, newsletters are a great vehicle to use to build a stronger community out of your audience. This project focuses on how to apply “audience centric journalism’’ approach to newsletters. It’s also attempts to design a “community-first” newsletter model by building and testing different models of newsletters for magazines who have different needs.",
    position: "Master’s Thesis",
  },
  {
    company: "Audience Consultancy Project",
    title: "FINANCIAL TIMES x NYU ",
    date: "(2019)",
    image: [require("../images/project.png")],
    description:
      "As a part of NYU’s Studio 20 class and Financial Times collaboration, the project aims to build a solution set for Financial Times by looking into how they can grow their audiences in the US. The first part features defining pain points, opportunities to growth and a roadmap for the research process. The second part involves conducting user interviews with FT subscribers, reporting detailed competitive analysis, and delivering comprehensive suggestions.",
    position: "Deputy Project Manager & User Experience Team Lead",
  },
  {
    company: "Independent magazine about food and memories",
    title: "SUMAC MAGAZINE ",
    date: "(2018 – 2020)",
    image: [
      require("../images/sumac1.jpeg"),
      require("../images/sumac2.jpeg"),
      require("../images/sumac3.jpg"),
      require("../images/sumac4.jpg"),
      require("../images/sumac5.jpg"),
    ],
    description:
      "SUMAC is a new sophisticated print magazine tearing down walls using food as a catalyst to tell inspiring human stories. More than just a food magazine, it's a whole community that aims to appreciate life, in all its wild diversity, through the lens of humanity's most basic, common ritual: sharing food and drink. In a world that's full of barriers, Sumac aims to tear down walls by breaking bread with strangers through the medium of a handsome 128-page print magazine full of stories from fascinating humans from around the planet.",
    position: "Co-Founder & Editor-in Chief",
  },
  {
    company: "Community Events",
    title: "SUMAC SOIREE SERIES ",
    date: "(2018 – 2020)",
    image: [
      require("../images/sumacs1.jpg"),
      require("../images/sumacs2.jpg"),
      require("../images/sumacs3.jpg"),
      require("../images/sumacs4.jpg"),
    ],
    description:
      "When we open our table, we build relationships and trust. Sharing a dish displays love and relationships. With the Sumac Soirée series, we aim to explore how food can unite us and catalyze exquisite conversation. In every Sumac Soirée night, our community  members bring a dish with a personal story, share the story behind the dish, and be a part of the collective storytelling format. (Previously: Ludlow House, Archestratus Books & Foods, Homecoming, Edy’s Grocer, Soho House Istanbul, MSA Culinary School)  ",
    position: "Creator & Host",
  },
  {
    title: "SANAYİ 313 PAPER ",
    date: "(2017 – 2020)",
    company: "Online/print publication",
    image: [],
    description:
      "SANAYI313PAPER is aiming to make hidden talents of Turkey and Turkish culture known internationally. Carefully selected content from the publication creates a niche, dense, bookish and collectible biannual print magazine curated and edited by Sanayi313 Editorial Team. Sanayi313 PAPER is designed to appeal to everyone who appreciates interior design, arts, and culture, food and drink enjoyed in their life.",
    position: "Content Strategist, Editor, Contributing Writer ",
  },
  {
    company: "Online/print publication",
    title: "NAIF GAZETTE ",
    date: "(2019 – 2021)",
    image: [require("../images/naif.png")],
    description:
      "Published by Naif Group, Naif Gazette is a bi-annual publication that focuses on design, street culture, and architecture. Naif Group is a design office that represents a movement within the industry to design as a response to art, history, nature and ideas. Not confined to institutionalized design concepts, Naif grows and evolves according to changing needs of a life.",
    position: "Executive Director, Contributing Writer",
  },
  {
    company: "Community Club",
    title: "MASIVA ",
    date: "(2018 – 2019)",
    image: [],
    description:
      "Published by Naif Group, Naif Gazette is a bi-annual publication that focuses on design, street culture, and architecture. Naif Group is a design office that represents a movement within the industry to design as a response to art, history, nature and ideas. Not confined to institutionalized design concepts, Naif grows and evolves according to changing needs of a life.",
    position: "Co-Founder",
  },
  {
    company: "Coffee table Book ",
    title: "EL-XYR: ABSOLUT COCKTAIL BOOK ",
    date: "(2017)",
    image: [require("../images/cocktail.png")],
    description:
      "258-page long mixology bible by award-winning bartender Cevat Yildirim. ",
    position: "Editor",
  },
  {
    company: "Print Publication & Event Series",
    title: "3.2.1 BY JAMESON & FILM CLUB ",
    date: "(2010 – 2011)",
    image: [],
    description:
      "The Jameson Cult Film Club celebrates cult film through creating deeply immersive and highly unique experiences. “3.2.1” is the editorial extension to Film Club, an annual movie magazine featuring exclusive interviews, essays, and features.",
    position: "Event Coordinator, Content Creator, Magazine Editor.",
  },
];

export const consulting = [
  {
    image: require("../images/workinton.jpeg"),
    title: "WORKINTON QATAR",
    year: "(2021 - Present)",
    col1: "Brand Development, Product Strategy",
    col2: "Launch Campaign, Creative Direction",
    col3: "Branding & Idea Creation, Brand Identity",
  },
  {
    title: "BECCA ISTANBUL",
    year: "(2021 - Present)",
    col1: "Business Development & Strategy",
    col2: "Brand Identity & Creation, Launch Campaign",
  },
  {
    image: require("../images/poshi.jpeg"),
    title: "POSHI NEW YORK",
    year: "(2018 - 2019)",
    col1: "Product Strategy, Launch Campaign",
    col2: "Creative & Editorial Direction, Brand Development",
  },
  {
    image: require("../images/santimetre.jpeg"),
    title: "SANTIMETRE NEW YORK",
    year: "(2018 - 2019)",
    col1: "Brand Strategy",
  },
  {
    image: require("../images/kolektif.png"),
    title: "KOLEKTIF HOUSE",
    year: "(2015 - 2019)",
    col1: "Launch Campaign, Creative Direction ",
    col2: "Branding & Idea Creation, Brand Identity",
  },
  {
    image: require("../images/melike.jpeg"),
    title: "MELIKE ALTINISIK ARCHITECTS",
    year: "(2018 - 2020)",
    col1: "Creative & Editorial Direction, Copywriting",
    col2: "Brand Positioning",
  },
  {
    image: require("../images/cantinery.png"),
    title: "LUCCA & CANTINERY",
    year: "(2013 - 2018)",
    col1: "Social Media Strategy, Content Creation",
    col2: "Editorial Direction, Launch Campaign (for Cantinery)",
  },
  {
    image: require("../images/gloria.png"),
    title: "GLORIA SPORTS ARENA",
    year: "(2015 - 2016)",
    col1: "Launch Campaign",
  },
  {
    image: require("../images/gloriahotels.png"),
    title: "GLORA HOTELS",
    year: "(2014 - 2016)",
    col1: "Social Media Strategy, Content Creation, Editorial Direction",
  },
  {
    image: require("../images/dogus2.jpeg"),
    title: "DOGUS ENTERTAINMENT GROUP",
    year: "(2016 - 2019)",
    col1: "Social Media Strategy, Editorial Direction, Content Creation",
  },
  {
    image: require("../images/redbull.png"),
    title: "RED BULL",
    year: "(2017 - 2018)",
    col1: "Content Creation & Production, Copywriting",
  },
  {
    image: require("../images/brownforman.png"),
    title: "BROWN FORMAN",
    year: "(2017 - 2018)",
    col1: "Event Curation, Content Creation, Copywriting",
  },
  {
    image: require("../images/emirgangroup.jpeg"),
    title: "EMIRGAN GROUP",
    year: "(2012 - 2018)",
    col1: "Brand Identity & Creation, Launch Campaign",
    col2: "Editorial Direction",
  },
  {
    image: require("../images/molton-brown.jpeg"),
    title: "MOLTON BROWN",
    year: "(2011 - 2013)",
    col1: "Social Media Launch, Content Creation, Copywriting",
  },
  {
    image: require("../images/muji.png"),
    title: "MUJI",
    year: "(2009 - 2011)",
    col1: "Content Creation, Copywriting",
  },
];

export const articles = [
  {
    title: "BEDFORD + BOWERY:",
    subtitle: "Kids Say the Wokest Things: Wisdom From the BLM Family Protest,",
    date: "June 2020",
    url: "https://bedfordandbowery.com/2020/06/kids-say-the-wokest-things-wisdom-from-the-blm-family-protest/",
  },
  {
    subtitle:
      "Looting and Shooting in Manhattan After Peaceful Protest in Brooklyn",
    date: "June 2020",
    url: "https://bedfordandbowery.com/2020/06/looting-and-shooting-in-manhattan-after-peaceful-protest-in-brooklyn/",
  },
  {
    subtitle:
      "As More Protesters Are Arrested, NYCLU and Others Threaten to Sue Over Curfew,",
    date: "June 2020",
    url: "https://bedfordandbowery.com/2020/06/as-more-protesters-are-arrested-nyclu-and-others-threaten-to-sue-over-curfew/",
  },
  {
    subtitle:
      "How Far-Right News Outlets In The US Amplify European Disinformation,",
    date: "December 2019",
    url: "https://talkingpointsmemo.com/prime/how-the-us-amplifies-european-disinformation",
  },
  {
    title: "TALKING POINTS MEMO:",
    subtitle:
      "How Far-Right News Outlets In The US Amplify European Disinformation,",
    date: "December 2019",
    url: "https://talkingpointsmemo.com/prime/how-the-us-amplifies-european-disinformation",
  },
  {
    title: "",
    subtitle:
      "Ladies first: The history of “being a first lady in Turkey Duvar in Engllish,",
    date: "November 2019",
    url: "https://www.duvarenglish.com/columns/2019/11/02/ladies-first",
  },
  {
    title: "",
    subtitle:
      "Someone tricked Neil deGrasse Tyson into EATING Turkish coffee, Hurriyet Daily News,",
    date: "August 2018",
    url: "https://www.hurriyetdailynews.com/someone-tricked-neil-degrasse-tyson-into-eating-turkish-coffee-135980",
  },
  {
    title: "",
    subtitle:
      "Interview: Taner Ceylan, “We Are Drenched in Blood, but Still Not Konocked Out",
    date: "",
    url: "https://www.hurriyetdailynews.com/someone-tricked-neil-degrasse-tyson-into-eating-turkish-coffee-135980",
  },
];