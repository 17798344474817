import { FaLinkedinIn, FaSpotify } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { ImPinterest2 } from "react-icons/im";
import React from "react";
import { Link } from "react-router-dom";

const SocialLinks = () => {
  return (
    <div className="relative w-full h-1/6 flex flex-col place-content-end mb-12">
      <div className="relative w-full flex flex-row place-content-center mt-10">
        <FaLinkedinIn
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/ali-tufan-ko%C3%A7-7256b045/",
              "_blank"
            )
          }
          className="w-6 h-6 hover:cursor-pointer"
        />
        <Link
          to="/static/Ali_Tufan_Koc_Cv.pdf"
          className="font-poppins font-regular text-lg ml-4 underline"
          target="_blank"
          download
        >
          CV
        </Link>
      </div>
    </div>
  );
};

export default SocialLinks;
