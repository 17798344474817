import React, { useState } from "react";
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";

const Header = () => {
  const [logo, setLogo] = useState(false);

  return (
    <nav className='fixed top-0 z-10 bg-white border border-black h-24 w-full flex items-center flex-row justify-between'>
      <h1 className='font-roboto font-semibold ml-10 text-2xl'>
        <Link to='/'>Ali Tufan Koç</Link>
      </h1>
      {!logo ? (
        <VscThreeBars
          className='rotate-0 h-8 w-8 mr-10 cursor-pointer'
          onClick={() => setLogo(true)}
        />
      ) : (
        <HamburgerMenu handleLogo={() => setLogo(false)} />
      )}
    </nav>
  );
};

export default Header;
